<template>
  <div class="mt-10">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Jenis SPP</label>
        <vs-input class="w-full" :value="spkData.header.jenis_spk" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="spkData.header.proyek.kode + ' - ' + spkData.header.proyek.nama" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Rekanan</label>
        <vs-input class="w-full" :value="spkData.header.rekanan.nama" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">No. SPP</label>
        <vs-input class="w-full" :value="spkData.header.no_spk" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl SPP</label>
        <vs-input class="w-full" :value="spkData.header.tgl_spk" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl Awal</label>
        <vs-input class="w-full" :value="spkData.header.tgl_awal" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl Akhir</label>
        <vs-input class="w-full" :value="spkData.header.tgl_akhir" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Masa (Hari)</label>
        <vs-input class="w-full" :value="spkData.header.masa" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Jenis Kontrak</label>
        <vs-input class="w-full" :value="spkData.header.jenis_kontrak" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Jenis Pekerjaan</label>
        <vs-input class="w-full" :value="spkData.header.jenis_pekerjaan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Lingkup Pekerjaan</label>
        <vs-input class="w-full" :value="spkData.header.lingkup_pekerjaan" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Files</label>
        <vs-button color="warning" v-if="spkData.header.files_url.length > 0" type="filled" size="small" icon-pack="feather" icon="icon-file" class="px-2" @click="showFiles(spkData.header)">Lihat Files</vs-button>
        <div v-else class="text-sm">-</div>
      </div>
    </div>

    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>

  </div>
</template>

<script>
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
export default {
  name: 'TabHeader',
  props: {
    isActive: { type: Boolean }
  },
  components: {
    FilesViewer
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  computed: {
    storeSpkShow () {
      return this.$store.state.proyek.spkShow
    },
    spkData () {
      return this.storeSpkShow.spkData
    }
  },
  methods: {
    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    }
  }
}
</script>
